import React from 'react';
import { Row, Col } from 'antd';
import AboutTile from '../../AbouTile';
import { stripTags, domHtml } from '../../../utils/stripTags';

import SEO from '../../Seo';

const pageText = {
  paraOne: `Proficient Gold Medalist Data Scientist with a virtuoso of working in the financial 
  services industry. Possessing ubiquitous Analytical Skill. The core ideology of my life is, 
  "Know what it is that drives you-motivates you and pursue it. Endeavor to work to make it happen". `,
  paraTwo: `Let your work speak for your unsaid words. Eager to orchestrate my Data Science ability 
  for new opportunities in EU/Germany regions.`,
};

const AboutMe = () => {
  const description = `${pageText.paraOne} ${stripTags(pageText.paraTwo)}`;
  return (
    <>
      <div>
        <SEO
          title="About"
          description={description}
          path=""
          keywords={['Mruga', 'Mruga Adhvaryu', 'Adhvaryu', 'Vyas', 'Berlin', 'Germany', 'Data Analyst', 'Data scientist', 'Senior Data scientist', 'Machine learning', 'Deep learning', 'Management Information System']}
        />
        <h1 className="titleSeparate">About Me</h1>
        <p>
          {pageText.paraOne}
        </p>
        <p dangerouslySetInnerHTML={domHtml(pageText.paraTwo)} />
      </div>
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="graduation.png"
            alt="graduation image"
            textH4="Pursued Bachelor's in"
            textH3="Statistics"
            height={60}
            width={60}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="graduation.png"
            alt="graduation image"
            textH4="Pursued Master's of Philosophy"
            textH3="Operations Research"
            height={60}
            width={60}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="graduation.png"
            alt="graduation image"
            textH4="Pursued Master's in"
            textH3="Statistics"
            height={60}
            width={60}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="web.png"
            alt="web image"
            textH4="Tableau Software"
            textH3="Tableau Data Scientist"
            height={60}
            width={60}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="web.png"
            alt="web image"
            textH4="Google AR VR"
            textH3="Introduction to Augmented Reality and ARCor"
            height={60}
            width={60}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="web.png"
            alt="web image"
            textH4="Self Taught Programmer"
            textH3="Thanks to the Web Resources"
            height={60}
            width={60}
          />
        </Col>
      </Row>
    </>
  );
};
export default AboutMe;
