import React from 'react';
import { Row, Col } from 'antd';
import ProgressBar from '../../Progress';

const Languages = () => (
  <div>
    <h2>My Languages</h2>
    <Row gutter={[20, 20]}>
      <Col xs={24} sm={24} md={12}>

        <ProgressBar
          percent={100}
          text="English"
        />
        <ProgressBar
          percent={60}
          text="German"
        />
      </Col>
      <Col xs={24} sm={24} md={12}>
        <ProgressBar
          percent={40}
          text="French"
        />
        <ProgressBar
          percent={40}
          text="Spanish"
        />
      </Col>
    </Row>
  </div>
);

export default Languages;
